"use client";

import { useEffect, useState } from "react";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  LogOutIcon,
  QuestionCircleIcon,
  UserCircleIcon,
} from "@nimbus-ds/icons";
import { signOut } from "next-auth/react";
import { useLocale, useTranslations } from "next-intl";

import { ConfirmationModal, MenuButton, Popover } from "~/components";
import { clearLocalStorageAndTrackingInfo } from "~/helpers";
import { trackAmplitudeEvent } from "~/helpers/analytics";
import { getLocaleFieldValue } from "~/helpers/i18n";
import useSelectedLocation from "~/hooks/useSelectedLocation";
import { useRouter } from "~/navigation";
import { useStoreInfo } from "~/providers/StoreInfoProvider";
import ShortcutListModalWidget from "~/widgets/ShortcutListModalWidget";
import SelectLocationModalWidget from "../SelectLocationModalWidget";

export default function AppMenu() {
  const t = useTranslations("widgets.app-menu");
  const router = useRouter();
  const locale = useLocale();
  const storeInfo = useStoreInfo();

  const { name, hasMulticd, country, adminLanguage, userConfig } =
    storeInfo || {};
  const storeName = getLocaleFieldValue(name, locale);
  const countryLocale = `${adminLanguage}_${country}`;
  const firstName = userConfig?.firstName ?? undefined;
  const lastName = userConfig?.lastName ?? undefined;
  const nameInitials = getInitials(firstName, lastName);

  const [menuOpen, setMenuOpen] = useState(false);
  const [shortcutListOpen, setShortcutListOpen] = useState(false);
  const [signOutConfirmationModalOpen, setSignOutConfirmationModalOpen] =
    useState(false);

  const [selectLocationModalOpen, setSelectLocationModalOpen] = useState(false);
  const { selectedOriginLocation, selectedFulfillmentLocation } =
    useSelectedLocation();

  useEffect(() => {
    if (menuOpen) {
      router.prefetch("/account");
      router.prefetch("/account/plans");
    }
  }, [menuOpen, router]);

  const handleVisibilityChange = (visible: boolean) => {
    setMenuOpen(visible);

    if (visible) {
      trackAmplitudeEvent("pdv_headerbar_profile_click");
    }
  };

  const handleMyAccountClick = () => {
    router.push("/account");
    trackAmplitudeEvent("pdv_profile_menu_payments_click");
  };

  const handlePlansClick = () => {
    router.push("/account/plans");
    trackAmplitudeEvent("pdv_profile_menu_plans_click");
  };

  const handleChangeLocationClick = () => {
    setSelectLocationModalOpen(true);
    setMenuOpen(false);
    trackAmplitudeEvent("pdv_profile_menu_location_click");
  };

  const handleSignOutClick = () => {
    setMenuOpen(false);
    setSignOutConfirmationModalOpen(true);
    trackAmplitudeEvent("pdv_profile_menu_logout_click");
  };

  const handleSignOutConfirmation = () => {
    clearLocalStorageAndTrackingInfo();
    signOut();
  };

  const handleHelpIconClick = () => {
    setShortcutListOpen(true);

    trackAmplitudeEvent("pdv_headerbar_help_click");
  };

  return (
    <>
      <div className="flex w-full items-center justify-end gap-3 border-b border-neutral-surface-highlight bg-neutral-background px-6 py-2">
        <QuestionCircleIcon
          width={24}
          height={24}
          onClick={(event) => {
            // Prevents the popover menu from opening
            event.stopPropagation();
            handleHelpIconClick();
          }}
          cursor="pointer"
        />
        <div className="flex flex-row items-center gap-2 p-2">
          <div className="flex h-10 w-10 items-center justify-center rounded-full bg-primary-surface text-base font-medium leading-5 text-primary-text-low">
            {!nameInitials ? (
              <UserCircleIcon width={24} height={24} />
            ) : (
              nameInitials
            )}
          </div>
          <Popover
            visible={menuOpen}
            onVisibility={(visible) => handleVisibilityChange(visible)}
            arrow={false}
            padding="small"
            width="250px"
            position="bottom-end"
            content={
              <div className="flex w-full flex-col gap-2">
                <MenuButton
                  label={t("menuItems.paymentsAndSubscriptions")}
                  onClick={handleMyAccountClick}
                />
                <MenuButton
                  label={t("menuItems.plans")}
                  onClick={handlePlansClick}
                />

                {hasMulticd && (
                  <MenuButton
                    label={t("menuItems.changeLocation")}
                    onClick={handleChangeLocationClick}
                  />
                )}

                <MenuButton
                  label={t("menuItems.signOut")}
                  startIcon={() => <LogOutIcon size={18} />}
                  onClick={handleSignOutClick}
                />
              </div>
            }
          >
            <div className="flex cursor-pointer flex-row items-center gap-2 text-primary-text-low ">
              <div className="flex flex-col items-start justify-center gap-1">
                <div className="text-sm font-medium">
                  {selectedOriginLocation
                    ? getLocaleFieldValue(
                        selectedOriginLocation.name,
                        countryLocale,
                      )
                    : storeName}
                </div>
              </div>
              {menuOpen ? (
                <ChevronUpIcon width={20} height={20} />
              ) : (
                <ChevronDownIcon width={20} height={20} />
              )}
            </div>
          </Popover>
        </div>
      </div>

      <ConfirmationModal
        open={signOutConfirmationModalOpen}
        onDismiss={() => setSignOutConfirmationModalOpen(false)}
        onConfirm={handleSignOutConfirmation}
        title={t("signOutConfirmationModal.title")}
        description={t("signOutConfirmationModal.description")}
        dismissButtonText={t("signOutConfirmationModal.dismissButtonText")}
        confirmButtonText={t("signOutConfirmationModal.confirmButtonText")}
      />

      <ShortcutListModalWidget
        open={shortcutListOpen}
        onAfterModalLeave={() => setShortcutListOpen(false)}
      />

      {selectLocationModalOpen && (
        <SelectLocationModalWidget
          onAfterModalLeave={() => setSelectLocationModalOpen(false)}
          initialOriginLocation={selectedOriginLocation}
          initialFulfillmentLocation={selectedFulfillmentLocation}
        />
      )}
    </>
  );
}

/**
 * Helpers
 */

function getInitials(firstName = "", lastName = "") {
  const firstNameInitials = firstName.split(" ").map((w) => w[0] || "");
  const lastNameInitials = lastName.split(" ").map((w) => w[0] || "");

  const firstInitial = firstNameInitials.shift() || lastNameInitials.shift();

  if (!firstInitial) {
    return undefined;
  }

  const secondInitial = lastNameInitials.shift() || firstNameInitials.shift();

  if (!secondInitial) {
    return undefined;
  }

  return `${firstInitial}${secondInitial}`.toUpperCase();
}
