"use client";

import { useEffect, useState } from "react";
import { isEmpty, map, max } from "lodash";
import { useTranslations } from "next-intl";

import Radio from "~/components/Radio";
import { Alert, Spinner } from "~/components";
import { appTRPC } from "~/providers/TRPCProvider";
import { PlanPeriod } from "~/types";
import PricingPlanCardWidget from "./PricingPlanCardWidget";

export default function AvailablePlansWidget() {
  const t = useTranslations("widgets.pricing-plans");

  const [selectedPlanId, setSelectedPlanId] = useState<string | undefined>();
  const [selectedPlanPeriod, setSelectedPlanPeriod] = useState<number>(
    PlanPeriod.MONTHLY,
  );

  const { data: planOptions, isPending: loadingPlanOptions } =
    appTRPC.planSubscriptions.getPlanOptions.useQuery();

  const { data: subscriptionSummary, isPending: loadingSubscriptionSummary } =
    appTRPC.planSubscriptions.getSummary.useQuery();

  useEffect(() => {
    const maxPeriodInDays = max(planOptions?.periods);

    if (maxPeriodInDays) {
      setSelectedPlanPeriod(maxPeriodInDays);
    }
  }, [planOptions?.periods]);

  const handlePlanSelected = (planId: string) => {
    setSelectedPlanId(planId);
  };

  if (loadingPlanOptions || loadingSubscriptionSummary) {
    return (
      <div className="flex w-full items-center justify-center">
        <Spinner />
      </div>
    );
  }

  if (!planOptions || !subscriptionSummary) {
    return <Alert appearance="danger">{t("fetchError")}</Alert>;
  }

  const {
    periods,
    basePriceAmountByCategory,
    optionsByPeriod,
    savingsPercentageByPeriod,
  } = planOptions;

  return (
    <div className="flex w-full flex-col gap-6">
      {!isEmpty(periods) && (
        <div className="flex justify-center gap-4">
          {map(periods, (periodInDays) => {
            const planPeriodName = t("planPeriodName", {
              period: periodInDays,
            });

            const savings = savingsPercentageByPeriod[periodInDays];
            const savingsText = savings ? ` -${savings.toFixed(0)}%` : "";

            return (
              <Radio
                as="button"
                key={periodInDays}
                name={planPeriodName}
                label={`${planPeriodName}${savingsText}`}
                checked={selectedPlanPeriod == periodInDays}
                onChange={() => setSelectedPlanPeriod(periodInDays)}
              />
            );
          })}
        </div>
      )}

      <div className="flex w-full max-w-[944px] items-end gap-6 pt-2">
        {map(
          optionsByPeriod[selectedPlanPeriod],

          ({ price: planPrice, plan }) => {
            const basePriceAmount = basePriceAmountByCategory?.[plan.category];

            return (
              <PricingPlanCardWidget
                key={planPrice.id}
                plan={plan}
                basePriceAmount={basePriceAmount}
                planPrice={planPrice}
                summary={subscriptionSummary}
                onPlanSelected={handlePlanSelected}
                isSelected={selectedPlanId === plan.id}
              />
            );
          },
        )}
      </div>
    </div>
  );
}
